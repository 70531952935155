<!--
 * @Descripttion: 返回组件
 * @Date: 2022-02-18 13:57:50
 * @Author: lingjunbin@imyfone.cn
 * @LastEditTime: 2024-11-18 17:51:22
 * @LastEditors: liujx@imyfone.cn
-->
<template>
  <div class="back">
    <div @click="goBack" class="go-back">
      <svg class="header-svg" width="9" height="20" viewBox="0 0 9 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 5l-5 5 5 5" stroke="#333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
      <span>返回</span>
    </div>

    <slot name="rightContent"></slot>
  </div>
</template>

<script >
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'GoBack',
  props: {
    backPos: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const router = useRouter()
    const store = useStore()
    function goBack () {
      if (props.backPos === 'notification') {
        store.commit('setMessageDel', true)
        return
      } else if (props.backPos === 'forum') {
        router.push({ path: '/forum' })
        return
      }
      router.back()
    }

    return {
      goBack
    }
  }
}
</script>

<style scoped lang="less">
.back{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    line-height: 40px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    border-radius: 6px;
    color: @default-text-color;
    padding: 0 24px;

    .go-back{
      cursor: pointer;
      display: flex;
      width: 80px;
      height: 100%;
      .header-svg{
        margin: 10px 8px 0 0;
        color: #777777;
      }

    }
    .go-back:hover{
      color: @active-text-color;
      .header-svg path{
        stroke: @active-text-color;
      }
    }
}
</style>
