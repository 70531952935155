/*
 * @Author: 何琦 heqi@imyfone.cn
 * @Date: 2023-04-12 17:00:52
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2025-02-20 11:47:36
 * @FilePath: \mfadmind:\hq\mfzj\src\store\modules\prize.js
 * @Description: prizeData
 */
const state = {
  prizeList: [], // 奖品列表数据
  selNumber: 1, // 每次抽取人数
  nowGift: {}, // 当前奖品数据
  mfOverlay: false // 自定义遮罩层
}

const mutations = {
  setPrizeList (state, payload) { // 设置奖品列表数据
    state.prizeList = payload
  },
  setSelNumber (state, payload) { // 设置抽取数量
    state.selNumber = payload
  },
  setNowGift (state, payload) { // 当前抽取奖品数据
    state.nowGift = payload
  },

  setMfOverlay (state, payload) { // 设置遮罩层
    state.mfOverlay = payload
  }
}

const getters = {
  luckyUser (state) { // 当前奖项的中奖用户
    const prizeId = state.nowGift.id || 0
    return state.prizeList.find(item => item.id === prizeId)?.lucky_users || []
  },
  giftNumber (state) { // 当前奖项的剩余数量
    return state.nowGift.all_num - state.nowGift.used_num
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
