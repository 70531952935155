/*
 * @Descripttion: 日志相关接口
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-18 17:42:48
 * @LastEditors: tangjz
 * @LastEditTime: 2023-06-05 10:13:52
 */

import { get, post } from '@/apis/method'

const getTeamList = (data) => get('/api/work_log/my_team', data)
const getTemplateList = (data) => get('/api/work_log/template_list', data)
const getTemplateInfo = (data) => get('/api/work_log/template_info', data)
const getJournalList = (data) => get('/api/work_log/log_list', data)
const getJournalInfo = (data) => get('/api/work_log/log_info', data)
const getReadUserList = (data) => get('/api/work_log/read_info', data)
const getReceiveList = (data) => get('/api/work_log/send_user_list', data)
const addJournal = (data) => post('/api/work_log/save_log', data)
const deleteJournal = (data) => post('/api/work_log/delete_log', data)
const updateJournal = (data) => post('/api/work_log/update_log', data)
const setJournalRead = (data) => post('/api/work_log/read', data)
const getLikeUserList = (data) => get('/api/work_log/like_info', data)
const getTeamInfo = (data) => get('/adminv2/attendance/department', data)
const getTeamUserList = (data) => get('/api/work_log/dept_member_list', data)
const getUnReadCount = (data) => get('/api/work_log/unread_count', data)
const getJournalLogStatu = (data) => get('/api/work_log/write_log_status', data)
const setBuriedPoint = (data) => post('/api/buried_point/insert_data', data)
const getMoreLikeUserList = (data) => get('/api/work_log/like_info_list', data) // 获取多个点赞用户列表
const getReportOkrInfo = (data) => get('/api/okr/report_okr_list', data)
const updateWithOkr = (data) => get('/api/work_log/update_with_okr', data)

export {
  getTeamList,
  getTemplateList,
  getTemplateInfo,
  getJournalList,
  getJournalInfo,
  getReadUserList,
  getReceiveList,
  addJournal,
  deleteJournal,
  setJournalRead,
  getLikeUserList,
  getTeamInfo,
  getTeamUserList,
  updateJournal,
  getUnReadCount,
  getJournalLogStatu,
  setBuriedPoint,
  getMoreLikeUserList,
  getReportOkrInfo,
  updateWithOkr
}
