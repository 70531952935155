<!-- 文字超出出现省略号 -->
<template>
    <el-tooltip
        ref="textTooltipRef"
        :disabled="disabled"
        :placement="placement"
        :hide-after="10"
        popper-class="text-tooltip">
      <template #content>
        <div v-html="content"></div>
      </template>
      <span
          ref="textSpanRef"
          v-html="content"
          :style="{
            'max-width': `${String(maxWidth).includes('%') ? maxWidth : `${maxWidth}px`}`,
            '--line-clamp': lines,
            ...styleValue
          }"
          :class="[
            'text-ellipsis',
            { 'multi-line': Number(lines) > 1 }
          ]"></span>
    </el-tooltip>
</template>

<script>
import { ref, onMounted, watch, onUnmounted } from 'vue'
export default {
  name: 'Tooltip',
  props: {
    content: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: [Number, String],
      default: 100
    },
    lines: {
      type: [Number, String],
      default: 1
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    styleValue: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props) {
    const disabled = ref(true)
    const textSpanRef = ref(null)

    const checkOverflow = () => {
      setTimeout(() => {
        const element = textSpanRef.value
        if (!element) {
          return
        }

        if (Number(props.lines) > 1) {
          disabled.value = element.scrollHeight <= element.clientHeight
        } else {
          disabled.value = element.scrollWidth <= element.offsetWidth
        }
      }, 0)
    }

    const resizeObserver = new ResizeObserver(() => {
      checkOverflow()
    })

    onMounted(() => {
      if (textSpanRef.value) {
        resizeObserver.observe(textSpanRef.value)
      }
    })

    watch(() => props.content, checkOverflow)

    onUnmounted(() => {
      if (textSpanRef.value) {
        resizeObserver.unobserve(textSpanRef.value)
      }
      resizeObserver.disconnect()
    })
    return {
      disabled,
      textSpanRef
    }
  }
}
</script>

<style lang="less">
.el-popper.text-tooltip {
    max-width: 400px;
}
</style>

<style scoped lang="less">
.text-ellipsis {
    display: block;

    &.multi-line {
        display: -webkit-box;
        -webkit-line-clamp: var(--line-clamp, 1);
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        text-wrap: auto;
    }

    &:not(.multi-line) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
