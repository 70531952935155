/*
 * @Description:静态资源路径管理
 * @Author: heqi
 * @Date: 2022-01-06 14:46:15
 * @LastEditTime: 2022-01-06 14:50:30
 * @LastEditors: heqi
 * @FilePath: \mfzj\src\store\modules\assets.js
 */
const state = {
  1000000: require('@/assets/img/36kr.png'),
  1000001: require('@/assets/img/juejing.png'),
  1000002: require('@/assets/img/quantum.png'),
  1000003: require('@/assets/img/lei.jpg')
}
export default {
  namespaced: true,
  state
}
